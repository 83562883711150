<template>
	<div id="mainpage" class="flex flex-col min-h-screen thank-you">

		<SiteHeader />

		<div class="section banner-section bg-siteOrange">

				<div class="banner container flex py-20 justify-center items-center lg:h-[700px] xl:px-20">
					<div class="text-center text-white">
						<div class="main-texttext-6xl leading-none font-Optima xs:text-7xl lg:text-[58px]">
							Thank you for getting in touch!
						</div>
						<img class="mt-8" src="@/assets/images/japan-car-banner-1.png" alt="japan-car-banner-1">
					</div>
				</div>

		</div>

		<SiteFooter />

	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'thank-you',
	components: {
		SiteHeader,
		SiteFooter,
	},
	mounted () {
		window.scrollTo(0, 0);

		// Event snippet for Conversion - Thank you Page conversion page
		// this.$gtag.event( 'conversion', { 'send_to': 'AW-349698048/VovhCNDRvsYCEIDw36YB' } );
	},
}
</script>
